'use strict';

import parentWishlist from 'pluginWishlist/product/wishlist';
import productBase from 'theme/js/product/base';

/**
 * renders the list up to a given page number
 * @param {number} pageNumber - current page number
 * @param {boolean} spinner - if the spinner has already started
 * @param {string} focusElementSelector - selector of the element to focus on
 */
function renderNewPageOfItems(pageNumber, spinner, focusElementSelector) {
    var publicView = $('.wishlistItemCardsData').data('public-view');
    var listUUID = $('.wishlistItemCardsData').data('uuid');
    var url = $('.wishlistItemCardsData').data('href');
    if (spinner) {
        $.spinner().start();
    }
    var scrollPosition = document.documentElement.scrollTop;
    var newPageNumber = pageNumber;
    $.ajax({
        url: url,
        method: 'get',
        data: {
            pageNumber: ++newPageNumber,
            publicView: publicView,
            id: listUUID
        }
    }).done(function (data) {
        $('.wishlistItemCards').empty();
        $('body .wishlistItemCards').append(data);

        if (focusElementSelector) {
            $(focusElementSelector).focus();
        } else {
            document.documentElement.scrollTop = scrollPosition;
        }
    }).fail(function () {
        $('.more-wl-items').remove();
    });
    $.spinner().stop();
}

parentWishlist.addToWishlist =
    ({
        productIdholder = 'data-wishlist-product-id',
        productWishlistWrapperSelector = '[data-add-to-wish-wrapper]',
        productIsInWishlistHolder = 'data-wishlist-inlist',
        wishlistSelector = '[data-add-to-wish-list]',
        productDetailSelector = '[data-product-top-content]',
        headerWishlistSelector = '[data-header-wishlist]',
        addToWishlistUrl = 'data-wishlist-addToWishlistUrl',
        removeFromWishlistUrl = 'data-wishlist-removeFromWishlistUrl',
        classList = {
            isInListClass: 'inlist',
            fadeOutAnimationClass: 'fade-out-in',
            hideClass: 'hidden',
            fadeOutHideAnimationClass: 'fade-out-hide'
        }
    } = {}) => {
        $('body').on('click', wishlistSelector, function (e) {
            e.preventDefault();
            e.stopPropagation();
            const button = $(this);
            const pid = button.attr(productIdholder);
            const optionsRaw = productBase.methods.getOptions(button.closest(productDetailSelector));
            const options = optionsRaw && JSON.parse(optionsRaw) || {};
            const wrapper = button.closest(productWishlistWrapperSelector);
            const optionId = options.optionId;
            const optionVal = options.selectedValueId;
            const inList = button.attr(productIsInWishlistHolder) === 'true';
            const url = inList && button.attr(removeFromWishlistUrl) || button.attr(addToWishlistUrl);

            if (!url || !pid) {
                return;
            }

            $.ajax({
                url: url,
                type: inList && 'get' || 'post',
                dataType: 'json',
                data: {
                    pid: pid,
                    optionId: optionId,
                    optionVal: optionVal
                },
                success: function (data) {
                    if (data.success) {
                        data.listIsEmpty && $(headerWishlistSelector).addClass(classList.fadeOutHideAnimationClass) || $(headerWishlistSelector).removeClass(classList.fadeOutHideAnimationClass) && $(headerWishlistSelector).removeClass(classList.hideClass);
                        inList && wrapper.removeClass(classList.isInListClass) && $(headerWishlistSelector).removeClass(classList.fadeOutAnimationClass) || wrapper.addClass(classList.isInListClass) && $(headerWishlistSelector).removeClass(classList.fadeOutAnimationClass) && setTimeout(() => {
                            $(headerWishlistSelector).addClass(classList.fadeOutAnimationClass);
                        }, 300);

                        button.attr(productIsInWishlistHolder, !inList);
                        let number = $('.wishlist-count-badge').html();
                        let inc = parseInt(number);
                        let toNumber = inList ? --inc : ++inc;
                        $('.wishlist-count-badge').html(toNumber);
                        const currentWishlistDiv = $('[data-add-to-wish-product-id=' + pid + ']');

                        if (currentWishlistDiv.length > 0) {
                            if (!inList) {
                                if (currentWishlistDiv.length > 0) {
                                    currentWishlistDiv.find('.wishlist-icon').addClass('inlist');
                                    $('button').attr(productIsInWishlistHolder, 'true');
                                }
                            } else {
                                currentWishlistDiv.find('.wishlist-icon').removeClass('inlist');
                                $('button').attr(productIsInWishlistHolder, 'false');
                            }
                        }

                        // Used for variant changes
                        const productIdsArray = window.WISHLIST_PRODUCT_IDS.split(',');
                        inList ? productIdsArray.splice(productIdsArray.indexOf(pid), 1) : productIdsArray.push(pid);
                        window.WISHLIST_PRODUCT_IDS = productIdsArray.join(',');

                        if (data.msg || data.emptyWishlistMsg) {
                            window.MessagesMgr.success(data.msg || data.emptyWishlistMsg);
                        }
                    } else if (data.msg) {
                        window.MessagesMgr.success(data.msg);
                    }
                },
                error: function (error) {
                    if (error.responseJSON && error.responseJSON.message) {
                        window.MessagesMgr.error(error.responseJSON.message);

                        // Remove item from existing wishlist
                        const productIdsArray = window.WISHLIST_PRODUCT_IDS.split(',');
                        productIdsArray.splice(productIdsArray.indexOf(pid), 1);
                        window.WISHLIST_PRODUCT_IDS = productIdsArray.join(',');

                        // Uncheck button
                        const currentWishlistDiv = $('[data-add-to-wish-product-id=' + pid + ']');
                        currentWishlistDiv.find('.wishlist-icon').removeClass('inlist');
                        currentWishlistDiv.find('.wishlist-icon').first().attr(productIsInWishlistHolder, 'false');
                    }
                }
            });
        });
    };

parentWishlist.moreWLItems = function () {
    $('body').on('click', '.more-wl-items', function () {
        var pageNumber = $('.wishlistItemCardsData').data('page-number');
        renderNewPageOfItems(pageNumber, true);
    });
};
module.exports = {
    ...parentWishlist
};
